export class GptDatasetMapper {
  static mapBackendToFrontend(datasetData) {
    return {
      datasetName: datasetData.name,
      indexColumnName: datasetData.idx_column_name,
      secondaryIndex: datasetData.secondary_idx_column,
      fillUpEmptyIndex: datasetData.is_idx_fillup_if_empty,
      imageColumn: datasetData.image_url_column,
      description: datasetData.description,
      datasetFile: null,
      datasetId: datasetData.dataset_id,
      tags: datasetData.tags ?? [],
      filterableFields: datasetData.filterable_fields ?? []
    }
  }

  static mapFrontendToBackend(datasetData, datasetUpdate = false) {
    const optionalDataMapper = {
      name: "datasetName",
      idx_column: "indexColumnName",
      secondary_idx_column: "secondaryIndex",
      image_url_column: "imageColumn",
      description: "description",
      dataset_file: "datasetFile",
      is_idx_fillup_if_empty: "fillUpEmptyIndex",
      tags: "tags",
      filterable_fields: "filterableFields"
    }

    if (datasetUpdate) {
      const formData = new FormData()
      for (const key in optionalDataMapper) {
        if (optionalDataMapper.hasOwnProperty(key) && datasetData[optionalDataMapper[key]]) {
          if(key === "tags" || key === "filterable_fields"){
              if(datasetData[optionalDataMapper[key]].length > 0){
                const arrayToString = datasetData[optionalDataMapper[key]].join(",")
                formData.append(key, arrayToString)
              }
          } else
          formData.append(key, datasetData[optionalDataMapper[key]])
        }
      }
      return formData
    } else {
      const formData = {}
      for (const key in optionalDataMapper) {
        if (optionalDataMapper.hasOwnProperty(key) && datasetData[optionalDataMapper[key]]) {
          if(key === "tags" || key === "filterable_fields"){
            const arrayToString = datasetData[optionalDataMapper[key]].join(",")
            formData[key] = arrayToString
          } else
          formData[key] = datasetData[optionalDataMapper[key]]
        }
      }
      return formData
    }


  }
}
