<template>
  <div>
    <div v-if="!commonState">
      <Loader
        v-if="accountLoading"
        class="mr-2 mt-6"
        loaderType="card-avatar, article, actions"
      />
      <div v-else>
        <v-row no-gutters justify="end">
          <v-spacer></v-spacer>
          <v-col cols="9" md="7">
            <v-row no-gutters>
              <v-col cols="8">
                <div>
                  <v-select
                    class="mr-2 mr-md-3"
                    v-model="selected_account"
                    :items="allAccount"
                    :label="$vuetify.lang.t('$vuetify.wallet.selected_account')"
                    item-text="account_name"
                    item-value="id"
                    return-object
                    outlined
                  >
                    <template v-slot:selection="data">
                      <div
                        class="d-flex"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                      >
                        <v-avatar
                          left
                          size="25px"
                          color="primary lighten-2"
                          class="mr-1"
                        >
                          {{
                            data.item.account_name.substring(0, 1).toUpperCase()
                          }}
                        </v-avatar>
                        <div class="d-flex flex-column">
                          <span
                            class="
                              d-block
                              font-weight-bold
                              text--primary text-truncate
                            "
                          >
                            <span>
                              {{ data.item.account_name }}
                            </span>
                            &nbsp;
                            <small>
                            ({{ passChar(data.item.public_key) }})
                            </small>
                            <v-chip
                              class="text-capitalize"
                              outlined
                              x-small
                              :color="
                                data.item.status === 'active'
                                  ? 'primary'
                                  : 'error'
                              "
                            >
                              {{ data.item.status }}
                            </v-chip>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <v-avatar left size="25px" color="primary lighten-2">
                            <span class="font-weight-bold">
                              {{
                                data.item.account_name
                                  .substring(0, 1)
                                  .toUpperCase()
                              }}
                            </span>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.account_name }}
                            <v-chip
                              class="text-capitalize"
                              outlined
                              x-small
                              :color="
                                data.item.status === 'active'
                                  ? 'primary'
                                  : 'error'
                              "
                            >
                              {{ data.item.status }}
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ passChar(data.item.public_key) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <v-btn
                    outlined
                    color="primary"
                    height="55px"
                    @click="addAccountClick()"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $vuetify.lang.t("$vuetify.wallet.add_account") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div>
          <v-alert
            v-if="selected_acc && selected_acc.secret_saved_consent === false"
            outlined
            dense
            type="error">
            <v-row align="center" no-gutters>
              <v-col class="grow">
                <p>{{ $vuetify.lang.t("$vuetify.wallet.save_secret_key_notice") }}</p>
              </v-col>
              <div class="mx-2"></div>
              <v-col class="shrink d-flex ">
                <v-btn color="primary" small outlined @click="showSecret" class="mr-2">
                  {{ $vuetify.lang.t("$vuetify.wallet.see_secret_key_btn") }}
                </v-btn>
                <v-btn color="primary" small outlined @click="saveConsent">
                  <v-icon>mdi-check</v-icon>{{ $vuetify.lang.t("$vuetify.wallet.understood_btn") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            v-if="selected_acc && selected_acc.status === 'created'"
            outlined
            type="error">
            <v-row align="center" no-gutters>
              <v-col class="grow">
                <p>{{ $vuetify.lang.t("$vuetify.wallet.activate_account_notice") }}</p>
              </v-col>
              <v-col class="shrink">
                <v-btn color="error" to="/wallet/buy">
                  {{ $vuetify.lang.t("$vuetify.wallet.buy") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-tabs v-model="activeTab" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route">
              <v-icon v-if="tab.icon" size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <v-img class="mr-2" v-else :src="tab.img"></v-img>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <router-view
            :item="selected_acc"
            :allAsset="allAsset"
            ref="buySection"
            @loadEditData="loadEditData"
            @loadAllAcc="loadAllAcc"
            @refreshAfterBuy="loadAllAccount"
            @openPasscode="openPasscode"
            @showSecret="showSecret"
          ></router-view>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="font-weight-bold">
          {{ $vuetify.lang.t("$vuetify.wallet.enter_passcode") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="passcodeForm" @submit.prevent="confirmClick()">
                <v-col cols="12" md="12">
                  <v-text-field
                    :class="{ 'mask-password': !showPasscode }"
                    :label="$vuetify.lang.t('$vuetify.wallet.passcode')"
                    v-model="passcode"
                    :placeholder="$vuetify.lang.t('$vuetify.wallet.passcode')"
                    autocomplete="off"
                    prepend-inner-icon="lock"
                    outlined
                    :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
                    type="text"
                    :rules="[required($vuetify.lang.t('$vuetify.wallet.passcode'))]"
                    @click:append="showPasscode = !showPasscode"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="isFromSecret" :disabled="isLoading" color="error" @click="clickCancel()">{{ $vuetify.lang.t('$vuetify.common.cancel') }}</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="confirmClick()">{{ $vuetify.lang.t('$vuetify.common.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="font-weight-bold">
          {{this.$vuetify.lang.t("$vuetify.wallet.create_passcode")}}
        </v-card-title>
        <v-card-text>
          <v-alert type="error" outlined dense class="mx-2">
            {{ $vuetify.lang.t("$vuetify.wallet.passcode_message") }}
          </v-alert>
          <v-row>
            <v-col>
              <v-form ref="createPasscodeForm" @submit.prevent="saveClick()">
                <v-col cols="12" md="12">
                  <v-text-field
                    :class="{ 'mask-password': !showPasscode }"
                    :label="$vuetify.lang.t('$vuetify.wallet.passcode')"
                    v-model="passcode"
                    :placeholder="$vuetify.lang.t('$vuetify.wallet.passcode')"
                    autocomplete="off"
                    prepend-inner-icon="lock"
                    outlined
                    :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
                    type="text"
                    :rules="[required($vuetify.lang.t('$vuetify.wallet.enter_passcode')), password($vuetify.lang.t('$vuetify.wallet.passcode'))]"
                    @click:append="showPasscode = !showPasscode"
                  ></v-text-field>
                  <v-text-field
                    :class="{ 'mask-password': !showConfirmPasscode }"
                    :label="$vuetify.lang.t('$vuetify.wallet.confirm_passcode')"
                    v-model="confirmPasscode"
                    :placeholder="$vuetify.lang.t('$vuetify.wallet.confirm_passcode')"
                    autocomplete="off"
                    prepend-inner-icon="lock"
                    outlined
                    :append-icon="
                      showConfirmPasscode ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    type="text"
                    :rules="[required($vuetify.lang.t('$vuetify.wallet.re_enter_passcode')), match(passcode)]"
                    @click:append="showConfirmPasscode = !showConfirmPasscode"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            :buttonType="RAISED_BUTTON"
            :buttonText="this.$vuetify.lang.t('$vuetify.common.save')"
            color="primary"
            @onClick="saveClick()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- secret kay save dialog  -->
    <v-dialog v-model="secretKeySaveDialog" persistent width="600">
        <v-card>
          <v-card-title class="headline white--text text--primary mb-4">
            {{ $vuetify.lang.t("$vuetify.wallet.secret_key") }}
          </v-card-title>
          <v-card-text>
            <div class="mb-8">
              <v-alert type="error" outlined>
                {{ $vuetify.lang.t("$vuetify.wallet.secret_save_msg") }}
              </v-alert>
            </div>
            <template>
              <v-text-field
                v-model="secret_key_mod"
                :label="$vuetify.lang.t('$vuetify.wallet.secret_key')"
                readonly
                outlined
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="copySecretKey()">
                        mdi-content-copy
                      </v-icon>
                    </template>
                    {{ secret_key_copy ? $vuetify.lang.t("$vuetify.common.copied") : $vuetify.lang.t("$vuetify.common.copy") }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="doneClick()">
              {{ $vuetify.lang.t("$vuetify.common.done") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import {
  REGISTER_WALLET_PASSCODE,
  CHECK_WALLET_PASSCODE,
  ACCESS_WALLET,
  LOAD_ALL_WALLET_ACCOUNT,
  WALLET_SECRET_KEY_SAVED_CONSENT,
  WALLET_SHOW_SECRET_KEY,
  LOAD_ALL_WALLET_ASSET,
} from "@/store/_actiontypes";
import { SET_WALLET_SELECTED_ACC } from "@/store/_mutationtypes";
import { timeToNextWalletToken } from "@/helpers/helper";
import validations from "@/helpers/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  RAISED_BUTTON,
  TEXT_BUTTON,
} from "@/components/common/button/_buttontypes";
import Button from "@/components/common/button/Button";
import Loader from "@/components/common/loader/Loader";
import lumen from "@/assets/images/lumen.svg";

export default {
  components: {
    Button,
    Loader,
  },
  computed: {
    ...mapState({
      allAccount: (state) => state.wallet.allAccount,
      selected_acc: (state) => state.wallet.selected_acc,
      allAsset: (state) => state.wallet.allAsset,
    }),
  },
  mounted() {
    let wallet = JSON.parse(localStorage.getItem("wallet_user"));
    if (!wallet || this.timeToNextWalletToken() < 1) {
      this.notCallPasscode = true;
      this.checkPasscode();
    } else if(this.$route.path === "/wallet"){
      this.$router.push("/wallet/details")
    } else if (this.selected_acc && this.selected_acc.status === "created") {
      this.loadAllAccount();
      this.called = false
    } else {
      this.selected_account = this.selected_acc;
      this.called && this.allAccount?.length < 1 && this.loadAllAccount();
      this.allAsset?.length < 1 && this.loadAllAssetFunc();
    }
    
    window.addEventListener('storage',(event) => {
      if(event.key === 'wallet_account') {
        this.selected_account = JSON.parse(event.newValue)
      };
    });
  },
  destroyed() {
    window.removeEventListener('storage',(event) => {
      if(event.key === 'wallet_account') {
        this.selected_account = JSON.parse(event.newValue)
      };
    });
  },
  data() {
    return {
      loading: false,
      ...validations,
      timeToNextWalletToken,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      dialog: false,
      createDialog: false,
      right: false,
      passcode: null,
      confirmPasscode: null,
      showPasscode: false,
      showConfirmPasscode: false,
      passType: true,
      timer: null,
      passCodeState: false,
      menu: false,
      activeTab: "/wallet/details",
      tabs: [
        {
          id: 0,
          title: this.$vuetify.lang.t('$vuetify.common.details'),
          icon: "mdi-eye",
          route: "/wallet/details"
        },
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.wallet.send_swap'),
          icon: "mdi-swap-horizontal-bold",
          route: "/wallet/send-swap",
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.wallet.buy'),
          img: lumen,
          route: "/wallet/buy",
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.wallet.transactions'),
          icon: "mdi-file-document-multiple",
          route: "/wallet/transactions",
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.wallet.rewards'),
          icon: "mdi-trophy-award",
          route: "/wallet/rewards",
        },
      ],
      accountLoading: false,
      acc_select: null,
      selected_account: null,
      commonState: false,
      notCallPasscode: false,
      isFromSecret: false,
      isLoading: false,
      secret_key: null,
      secretKeySaveDialog: false,
      secret_key_mod: null,
      secret_key_copy: false,
      called: true,
    };
  },
  watch: {
    selected_account: {
      handler(val, oldVal) {
        let wallet = JSON.parse(localStorage.getItem("wallet_user"));
        if (!wallet || this.timeToNextWalletToken() < 1) {
          this.letChange(val);
          !this.notCallPasscode && this.checkPasscode();
          this.notCallPasscode = false;
        } else {
          this.letChange(val);
        }
        if(val && oldVal && val.status !== oldVal.status){
          this.loadAllAccount()
        }
      },
      deep: true
    },
    '$route.query.id': {
      handler: function(order_id) {
        if(order_id) {
          setTimeout(() => {
            this.$refs.buySection && this.$refs.buySection.updateWyreOrder(order_id)
          }, 2000)
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    ...mapActions("wallet", [
      REGISTER_WALLET_PASSCODE,
      CHECK_WALLET_PASSCODE,
      ACCESS_WALLET,
      LOAD_ALL_WALLET_ACCOUNT,
      WALLET_SECRET_KEY_SAVED_CONSENT,
      WALLET_SHOW_SECRET_KEY,
      LOAD_ALL_WALLET_ASSET
    ]),
    ...mapMutations("wallet", [SET_WALLET_SELECTED_ACC]),
    letChange(val) {
      this.SET_WALLET_SELECTED_ACC(val);
    },
    //edit account
    loadEditData(name) {
      let storeAcc = this.selected_acc;
      storeAcc.account_name = name;
      this.LOAD_ALL_WALLET_ACCOUNT({})
        .then((res) => {
          this.SET_WALLET_SELECTED_ACC(storeAcc);
          this.selected_account = storeAcc;
        })
        .catch((err) => {});
    },
    // after delete account load all account
    loadAllAcc() {
      this.SET_WALLET_SELECTED_ACC(null);
      this.loadAllAccount();
    },
    loadAllAccount() {
      this.accountLoading = true;
      this.LOAD_ALL_WALLET_ACCOUNT({}).then(
        (res) => {
          let allAcc = res.data.accounts;
          if (allAcc.length < 1) {
            this.$router.push({
              path: "/add-account",
            }).catch(() => {})
          }
          if (this.selected_acc) {
            let acc = allAcc.filter(
              (item) => item.id === this.selected_acc.id
            )[0];
            this.SET_WALLET_SELECTED_ACC(acc);
            this.selected_account = acc;
          }else {
            this.SET_WALLET_SELECTED_ACC(allAcc[0]);
            this.selected_account = allAcc[0];
          }
          this.accountLoading = false;
        },
        (error) => {
          this.accountLoading = false;
        }
      );
    },
    loadAllAssetFunc() {
      this.accountLoading = true;
      this.LOAD_ALL_WALLET_ASSET({}).then(
        (res) => {
          this.accountLoading = false;
        },
        (error) => {
          this.accountLoading = false;
        }
      );
    },
    addAccountClick() {
      this.$router.push({
        path: "/add-account",
      });
    },
    openPasscode() {
      this.checkPasscode();
    },
    checkPasscode() {
      this.CHECK_WALLET_PASSCODE({}).then(
        (res) => {
          if (res.data.exist) {
            this.createDialog = false;
            this.dialog = true;
            this.commonState = true;
          } else {
            this.dialog = false;
            this.createDialog = true;
            this.commonState = true;
          }
        },
        (error) => {}
      );
    },
    confirmClick() {
      if (!this.$refs.passcodeForm.validate()) return;
      if(this.isFromSecret) {
        this.isLoading = true;
        this.ACCESS_WALLET({
          passcode: this.passcode,
        })
          .then((res) => {
            this.WALLET_SHOW_SECRET_KEY({
              body_json: {
                passcode: this.passcode
              },
              public_key: this.selected_acc?.public_key
            }).then((res) => {
              this.isFromSecret = false;
              this.dialog = false;
              this.isLoading = false;
              this.secretKeySaveDialog = true;
              this.secret_key = res.data.secret_key;
              this.secret_key_mod = this.maskSecret(this.secret_key);
              this.passcode = null;
            }).catch((err) => {
              this.isLoading = false;
            })
          })
          .catch((err) => {
            this.isLoading = false;
          })
      }else {
        this.ACCESS_WALLET({
          passcode: this.passcode,
        })
          .then((res) => {
            this.dialog = false;
            this.commonState = false;
            this.loadAllAccount();
            this.allAsset?.length < 1 && this.loadAllAssetFunc();
            this.passcode = null;
            if(this.$route.path === "/wallet"){
              this.$router.push("/wallet/details")
            }
            if(this.$route.query.id) {
              setTimeout(() => {
                this.$refs.buySection && this.$refs.buySection.updateWyreOrder(this.$route.query.id)
              }, 2000)
            }
          })
          .catch((err) => {});
      }
    },
    saveClick() {
      if (!this.$refs.createPasscodeForm.validate()) return;
      this.REGISTER_WALLET_PASSCODE({
        passcode: this.passcode,
      })
        .then((res) => {
          this.createDialog = false;
          this.commonState = false;
          this.loadAllAccount();
        })
        .catch((err) => {});
    },
    saveConsent() {
      if(this.timeToNextWalletToken() < 1) {
        this.dialog = true;
      }else {
        this.WALLET_SECRET_KEY_SAVED_CONSENT({
          public_key: this.selected_acc.public_key
        }).then((res) => {
          let acc = this.allAccount.filter(item => item.public_key === this.selected_acc.public_key)[0]
          this.letChange(acc)
        }).catch((err) => {})
      }
    },
    showSecret() {
      this.isFromSecret = true;
      this.dialog = true;
    },
    clickCancel() {
      this.dialog = false;
      this.isFromSecret = false;
      this.passcode = null;
    },
    copySecretKey() {
      navigator.clipboard.writeText(this.secret_key);
      this.secret_key_copy = true;
    },
    doneClick() {
      this.secretKeySaveDialog = false;
    },
    maskSecret(str) {
      return `${str.substring(0, 1)}${"*".repeat(str.length - 1)}`;
    },
    passChar(str) {
      return `${str.substring(0, 5)}**********${str.substring(
        str.length - 6,
        str.length
      )}`;
    },
  },
};
</script>
