import PersonalizerApi from "@/services/personalizer_api";
import {
    GET_GPT_DOCUMENTS,
    TERMINATE_GPT_DOCUMENTS,
    CREATE_GPT_DOCUMENT,
    UPDATE_GPT_DOCUMENT,
    GET_GPT_DOCUMENT_BY_ID,
} from "@/store/_actiontypes";
import {
    SET_GPT_DOCUMENTS,
    SET_GPT_DOCUMENT,

} from "@/store/_mutationtypes";
import { updateLoaderTo } from "./account";

export const gptDocumentInitialData = [{ "": "" }]

const state = {
    gptDocuments: [],
    gptDocument: gptDocumentInitialData,
};

const actions = {
    [CREATE_GPT_DOCUMENT]({ commit }, { project_id, dataset_id, documents }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.post(`gpt/datasets/${dataset_id}/documents`, { documents: [documents], is_background_task: false },
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [UPDATE_GPT_DOCUMENT]({ commit }, { project_id, dataset_id, document_id, data }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.put(`gpt/datasets/${dataset_id}/documents/${document_id}`, { data: data },
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        }
        );
    },
    [GET_GPT_DOCUMENTS]({ commit }, { project_id, dataset_id, start, size, keyword, status_value, train_status_value, sort }) {
        updateLoaderTo(true);
        if(!start) start = 0;
        if(!size) size = 10;
        let query = `sort=${sort}&start=${start}&size=${size}`;
        if(keyword) query = query.concat(`&keyword=${keyword}`);
        if(status_value) query = query.concat(`&${status_value}`);
        if (train_status_value) query = query.concat(`&${train_status_value}`);
        
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(`gpt/datasets/${dataset_id}/documents?${query}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                    (response) => {
                        commit(SET_GPT_DOCUMENTS, response.data?.response.documents);
                        updateLoaderTo(false);
                        resolve(response);
                    },
                    (error) => {
                        updateLoaderTo(false);
                        reject(error);
                    }
                );
        });
    },
    [GET_GPT_DOCUMENT_BY_ID]({ commit }, { project_id, dataset_id, document_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(`gpt/datasets/${dataset_id}/documents/${document_id}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    commit(SET_GPT_DOCUMENT,  response.data?.detail);
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [TERMINATE_GPT_DOCUMENTS]({ commit }, { project_id, dataset_id, documentIds }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.delete(`gpt/datasets/${dataset_id}/documents`, {
                data: {
                    ids: documentIds,
                    is_delete_all: documentIds.length === 0,
                    // is_background_task: documentIds.length === 0 ? true : documentIds.length > 1 ? true : false
                },
                headers: {
                    'Content-Type': 'application/json',
                    "project-id": project_id,
                }
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
};

const mutations = {
    [SET_GPT_DOCUMENTS](state, allDocuments) {
        state.gptDocuments = allDocuments;
    },
    [SET_GPT_DOCUMENT](state, document) {
        state.gptDocument = document;
    },
};

export const document = {
    namespaced: true,
    state,
    actions,
    mutations,
};
