<template>
  <v-dialog v-model="open" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-2">
        <div>
          <v-data-table
            :headers="fileGeneratedHistoryHeaders"
            :items="fileGeneratedHistory"
            :loading="fileGeneratedHistoryLoading"
            :server-items-length="totalHits"
            :items-per-page="itemsPerPage"
            :page="page"
            :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
            :footer-props="{itemsPerPageOptions: [5, 10], showFirstLastPage: true}"
            @update:page="page = $event"
            @update:items-per-page="itemsPerPage = $event">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item.id">
                <template v-if="item.status === 'success' || item.status === 'pending'">
                  <td>
                    {{ formatDate(extractMessageProperty(item.message, "start_date")) }}
                    ~
                    {{ formatDate(extractMessageProperty(item.message, "end_date")) }}
                  </td>
                  <td>
                    <span class="text-capitalize">{{ item.status }}</span>
                  </td>
                  <td>
                      <span v-if="extractMessageProperty(item.message, 'file_url') ===
    'N/A' ||
    isExpired(extractMessageProperty(item.message, 'expiration'))
    ">N/A</span>
                    <a v-else :href="extractMessageProperty(item.message, 'file_url')" target="_blank">
                      {{ $vuetify.lang.t("$vuetify.download_btn_txt") }}
                    </a>
                  </td>
                  <td>
                    {{
                      isExpired(
                        extractMessageProperty(item.message, "expiration")
                      )
                        ? $vuetify.lang.t(
                          "$vuetify.logs_file_generation.expired"
                        )
                        : formatDate(
                          extractMessageProperty(item.message, "expiration")
                        )
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="fileGeneratedHistoryHeaders.length" class="text-center">
                    File generation failed.
                  </td>
                </template>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn outlined color="error" @click="closeModal">{{
            $vuetify.lang.t("$vuetify.close_btn_txt")
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GET_ALL_TASKS } from "@/store/_actiontypes";
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    history_type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fileGeneratedHistoryLoading: false,
      fileGeneratedHistory: [],
      totalHits: 0,
      itemsPerPage: 5,
      page: 1,

      fileGeneratedHistoryHeaders: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.date_range"
          ),
          value: "date_range",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.logs_file_generation.file_url"),
          value: "file_url",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.expiration"
          ),
          value: "expiration",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedProject: (state) => state.project.selectedProject,
      dateRangeText: function () {
        return this.fileDateRange.join(" ~ ");
      },
    }),
  },

  watch: {
    page(_newVal) {
      this.getFileGeneratedHistory();
    },
    itemsPerPage(_newVal) {
      this.page = 1;
      this.getFileGeneratedHistory();
    },
  },

  mounted() {
    this.getFileGeneratedHistory();
  },

  methods: {
    ...mapActions("task", [GET_ALL_TASKS]),

    getFileGeneratedHistory() {
      this.fileGeneratedHistoryLoading = true;
      this.GET_ALL_TASKS({
        project_id: this.selectedProject.id,
        task_type: `generate_file_${this.history_type}`,
        task_status: "all_status",
        start: (this.page - 1) * this.itemsPerPage,
        size: this.itemsPerPage,
      })
        .then(
          (response) => {
            this.fileGeneratedHistory = response.data.detail.response;
            this.totalHits = response.data.detail.total_train;
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.fileGeneratedHistoryLoading = false;
        });
    },

    extractMessageProperty(message, key) {
      try {
        const validJsonString = message.replace(/'/g, '"');
        const parsedMessage = JSON.parse(validJsonString);
        return parsedMessage[key];
      } catch (error) {
        console.error("Failed to parse message:", error);
        return "Invalid data";
      }
    },

    formatDate(date) {
      if (date === "N/A") return "N/A";
      else return new Date(date).toLocaleString("en-CA").split(",")[0];
    },

    isExpired(expiration) {
      if (expiration === "N/A") return false;
      else return new Date(expiration) < new Date();
    },
  },
};
</script>
