import Api from "@/services/api";
import PersonalizerApi from "@/services/personalizer_api";
import {
    LOAD_ALL_PROJECT,
    LOAD_PROJECT_BY_ID,
    SAVE_PROJECT,
    UPDATE_PROJECT,
    UPGRADE_SUBSCRIPTION,
    START_PROJECT,
    STOP_PROJECT,
    TERMINATE_PROJECT,
    LOAD_PROJECT_COST,
    LOAD_ALL_CREDENTIAL,
    SAVE_CREDENTIAL,
    UPDATE_CREDENTIAL,
    GET_RANK_SETTINGS,
    GET_MAPPER,
    CREATE_MAPPER,
    UPDATE_MAPPER,
    GET_INDICES,
    CREATE_INDICES,
    CREATE_RANK,
    UPDATE_RANK_SETTINGS,
    UPLOAD_RECOMMENDER_DATA,
    ITEMS_TRAIN,
    IMAGES_TRAIN,
    RANKS_TRAIN,
    GET_TASKS_STATUS,
    LOAD_TRAIN_TASKS,
    GET_PREVIOUSLY_SETUP_STATE,
    LOAD_PROJECT_COUNTS,
    GET_GPT_PROFILES,
    GET_GPT_PROFILE_BY_ID,
    CREATE_GPT_PROFILE,
    UPDATE_GPT_PROFILE_STATE,
    UPDATE_GPT_PROFILE_API,
    GET_GPT_DATASETS,
    GET_GPT_DATASETS_SUMMARY,
    GET_GPT_DATASET_BY_ID,
    CREATE_GPT_DATASET,
    TERMINATE_GPT_DATASET,
    UPDATE_GPT_DATASET_API,
    UPDATE_GPT_DATASET_STATE,
    TRAIN_GPT_DATASET,
    CREATE_GPT_SETTINGS,
    UPDATE_GPT_SETUP_STATE,
    GET_ALL_TASKS,
    GET_GPT_SETTINGS,
    DELETE_GPT_PROFILE,
    DELETE_GPT_SETTINGS,
    UPDATE_GPT_SETTINGS,
    GENERATE_FILE,
    GET_CATALOG_ITEMS,
    REMOVE_CATALOG_ITEMS,
    UPDATE_CATALOG_ITEM
} from "@/store/_actiontypes";
import {
    SET_ALL_PROJECT,
    SET_PROJECT_BY_ID,
    SET_MAPPER,
    SET_PROJECT_SETUP_STATE,
    SET_PROJECT_COST,
    SET_ALL_CREDENTIAL,
    SET_PROJECT_COUNTS,
    SET_TRAIN_TASKS,
    SET_PREVIOUSLY_SETUP_STATE,
    SET_GPT_PROFILES,
    SET_GPT_PROFILE,
    SET_GPT_DATASETS,
    SET_GPT_DATASET,
    SET_GPT_DATASET_SUMMARY,
    SET_GPT_SETUP_STATE,
    SET_CATALOG_ITEMS,
    CATALOG_SCROLL_ID_STACK
} from "@/store/_mutationtypes";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";
import { GptDatasetMapper } from "@/helpers/dataMappers/gptDataset";
import { updateLoaderTo } from "./account";
import { GPT_MODELS } from "@/global/_constants";
export const gptProfileInitialData = GptProfileMapper.mapBackendToFrontend({
  name: "",
  intro: "",
  system: "",
  model: "",
  modelList: GPT_MODELS,
  temperature: 0,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  stop: [],
  search_max_token: 2500,
  completion_token: 2000,
  vision_settings: {
    resolution: "low",
    is_image_context_enabled: true,
  },
  id: undefined,
  chat_history_length: 3,
  is_personalizer_only: false,
  is_auto_evaluation: false,
});

export const gptDatasetInitialData = GptDatasetMapper.mapBackendToFrontend({
  dataset_id: "",
  description: "",
  idx_column_name: "",
  image_url_column: "",
  is_idx_fillup_if_empty: true,
  name: "",
  secondary_idx_column: "",
});

const state = {
  allProject: [],
  selectedProject: {},
  mapper: null,
  projectCost: {},
  allCredential: [],
  trainTasks: [],
  previouslySetupState: [],
  projectCounts: [],
  catalogItems: [],
  gptProfiles: {
    profiles: [],
    returned_hits: 0,
    total_hits: 0
  },
  selectedGptProfile: gptProfileInitialData,
  gptDatasets: {
    datasets: [],
    returned_hits: 0,
    total_hits: 0
  },
  selectedGptDataset: gptDatasetInitialData,
  initialGptDatasetTrainingState: {},
  gptSetupState: {
    completed: false,
    gpt_profile: { completed: false, profile_id: null },
    default_profile: { completed: false },
    dataset: {
      task_id: null,
      status: null,
      dataset_id: null,
      completed: false,
    },
    training: { task_id: null, status: null, completed: false },
  },
  gptDatasetSummary: {},
  catalogScrollIdStack: []
};

const getters = {
  mapper: (state) => state.mapper,
  credentials: (state) => {
    return state.allCredential.map(credential => {
      const { key_type, key_metadata } = credential;

      const whitelists = (key_metadata && key_metadata.whitelists) ? key_metadata.whitelists : [];

      let riskLevel = "No Risk";
      let color = "green";

      if (key_type === "client") {
        if (whitelists.length === 0 || whitelists.includes("localhost")) {
          riskLevel = "High Risk";
          color = "error";
        }
      } else { // key_type === 'project'
        if (whitelists.length === 0) {
          riskLevel = "Medium";
          color = "warning";
        } else if (whitelists.includes("localhost")) {
          riskLevel = "High Risk";
          color = "error";
        }
      }

      const security = {
        level: riskLevel,
        color: color,
        textColor: "white"
      }

      return {
        ...credential,
        security: security
      };
    });
  },
}

const actions = {
  [LOAD_ALL_PROJECT]({ commit }, { solution_type }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const apiUrl = solution_type
        ? `projects?solution_type=${solution_type}`
        : "projects";

      Api.get(apiUrl).then(
        (response) => {
          let allProject = response.data.projects;
          // allProject.forEach((element) => {
          // element.projects_solutions = element.solutions.filter(
          //   (obj) => {
          //     return obj.enable === true;
          //   }
          // );
          // });
          commit(SET_ALL_PROJECT, allProject);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TRAIN_GPT_DATASET](
    { commit },
    { project_id, datasetId, trainType, batchSize }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const trainingDataset = {
        batch_size: batchSize,
        train_type: trainType,
      };
      PersonalizerApi.post(`gpt/datasets/${datasetId}/train`, {
        ...trainingDataset,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_PROFILE_BY_ID]({ commit }, { project_id, profile_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_PROFILE, gptProfileInitialData);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/profiles/${profile_id}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(
            SET_GPT_PROFILE,
            response.data?.detail?.response
              ? GptProfileMapper.mapBackendToFrontend(
                  response.data?.detail?.response
                )
              : gptProfileInitialData
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_GPT_PROFILE]({ commit, state }, { project_id, profile }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const mappedGPTProfileData =
        GptProfileMapper.mapFrontendToBackend(profile);
      PersonalizerApi.post(`gpt/profiles`, { ...mappedGPTProfileData },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(SET_GPT_PROFILE, {
            ...profile,
            id: response.data?.detail?.profile_id,
          });
          commit(SET_GPT_PROFILES, {
            profiles: [
              { ...profile, id: response.data?.detail?.profile_id },
              ...state.gptProfiles.profiles,
            ],
            returned_hits: state.gptProfiles.returned_hits + 1,
            total_hits: state.gptProfiles.total_hits + 1,
          });
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  //creates default profile
  [CREATE_GPT_SETTINGS](
    { commit },
    { project_id, default_profile, default_vision_profile, bot_mode_strict }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.post(`gpt/settings`, {
        default_profile,
        default_vision_profile,
        bot_mode_strict,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/settings`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_SETTINGS]({ commit }, { project_id, gptSetting }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.put(`gpt/settings`, {...gptSetting},
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
            (response) => {
                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[CREATE_GPT_SETTINGS]({ commit }, { project_id, default_profile, default_vision_profile, bot_mode_strict }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.post(`gpt/settings`, {default_profile, default_vision_profile, bot_mode_strict},
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
            (response) => {
                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[GET_GPT_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/settings`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
            (response) => {

                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[DELETE_GPT_SETTINGS]({ commit }, {project_id, fields = [] }) {
  updateLoaderTo(true);
  return new Promise((resolve, reject) => {
    PersonalizerApi.delete(`gpt/settings`,{
      headers: {
        'Content-Type': 'application/json',
        'project-id': project_id
      },
      data: {fields}
    }).then(
      (response) => {
        updateLoaderTo(false);
        resolve(response);
      },
      (error) => {
        updateLoaderTo(false);
        reject(error);
      }
    );
  });
},

  [DELETE_GPT_PROFILE]({ commit, state }, { project_id, profile_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.delete(`gpt/profiles/${profile_id}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
            const updatedGptProfiles = state.gptProfiles.profiles.filter(item => item.id !== profile_id)
            commit(SET_GPT_PROFILES, {...state.gptProfiles, profiles: updatedGptProfiles})
            updateLoaderTo(false);
            resolve(response);
        },
        (error) => {
            updateLoaderTo(false);
            reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_PROFILE_API]({ commit }, { project_id, profile }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const mappedGPTProfileData =
        GptProfileMapper.mapFrontendToBackend(profile);
      delete mappedGPTProfileData.id;
      PersonalizerApi.put(`gpt/profiles/${profile.id}`, {
        ...mappedGPTProfileData,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          // commit(SET_GPT_PROFILE, profile_data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_PROFILES]({ commit }, { project_id, start, size }) {
    updateLoaderTo(true);
    let uri = `gpt/profiles`;
    if (size) {
      uri = `${uri}?start=${start}&size=${size}`;
    }
    
    commit(SET_GPT_PROFILES, []);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(uri,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(SET_GPT_PROFILES, response.data?.detail?.response);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_PROFILE_STATE]({ commit }, { profile }) {
    commit(SET_GPT_PROFILE, profile);
  },
  [UPDATE_GPT_DATASET_STATE]({ commit }, { dataset }) {
    commit(SET_GPT_DATASET, dataset);
  },
  [UPDATE_GPT_SETUP_STATE]({ commit }, value) {
    commit(SET_GPT_SETUP_STATE, value);
  },
  [GET_GPT_DATASETS]({ commit }, { project_id, keyword, sort_by_created_at, is_idx_fillup_if_empty, start, size }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASETS, []);

    if(!start) start = 0;
    if(!size) size = 10;

    let query = `start=${start}&size=${size}`;
    if(keyword) query = query.concat(`&keyword=${keyword}`);
    if(sort_by_created_at) query = query.concat(`&sort_by_created_at=${sort_by_created_at}`);
    if(is_idx_fillup_if_empty !==undefined && is_idx_fillup_if_empty !== "" ) {
      query = query.concat(`&is_idx_fillup_if_empty=${is_idx_fillup_if_empty}`);
    }
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/datasets?${query}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(SET_GPT_DATASETS, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_DATASETS_SUMMARY]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASET_SUMMARY, {});
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/datasets/${dataset_id}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(SET_GPT_DATASET_SUMMARY, response.data?.response.dataset);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_ALL_TASKS](
    { commit },
    { project_id, task_type, task_status, start = 0, size = 10 }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(
        `tasks?task_type=${task_type}&task_status=${task_status}&start=${start}&size=${size}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_DATASET_BY_ID]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASET, gptDatasetInitialData);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`gpt/datasets/${dataset_id}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          commit(
            SET_GPT_DATASET,
            GptDatasetMapper.mapBackendToFrontend(
              response.data?.response.dataset
            )
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_GPT_DATASET]({ commit }, { project_id, dataset }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const clonedDataset = { ...dataset };
      delete clonedDataset.datasetId;
      delete clonedDataset.datasetFile;
      const mappedGPTDatasetDataFormData =
        GptDatasetMapper.mapFrontendToBackend(clonedDataset);
        PersonalizerApi.post(`gpt/datasets`, mappedGPTDatasetDataFormData,
          {
            headers: {
              "project-id": project_id,
            },
          }
        ).then(
        (response) => {
          commit(SET_GPT_DATASETS, [
            { ...dataset, dataset_id: response.data?.detail?.dataset_id },
          ]);
          commit(SET_GPT_DATASET, {
            ...dataset,
            datasetId: response.data?.detail?.dataset_id,
          });
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TERMINATE_GPT_DATASET]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.delete(`gpt/datasets/${dataset_id}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_DATASET_API]({ commit }, { project_id, dataset }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const clonedDataset = { ...dataset };
      delete clonedDataset.datasetId;
      const mappedGPTDatasetDataFormData =
        GptDatasetMapper.mapFrontendToBackend(clonedDataset, true);
        PersonalizerApi.put(
        `gpt/datasets/${dataset.datasetId}`,
        mappedGPTDatasetDataFormData,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_PROJECT_BY_ID]({ commit }, { project_id }) {
    updateLoaderTo(true);
    commit(SET_PROJECT_BY_ID, {});
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}`).then(
        (response) => {
          commit(SET_PROJECT_BY_ID, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_PROJECT_COST](
    { commit },
    { solutionIDList, monthlyPV, monthlyUU, itemCount, subscriptionType }
  ) {
    updateLoaderTo(true);
    commit(SET_PROJECT_COST, {});
    return new Promise((resolve, reject) => {
      Api.post(`projects/cost`, {
        solution_ids: solutionIDList,
        monthly_pv: monthlyPV,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        subscription_type: subscriptionType,
      }).then(
        (response) => {
          let projectCost = response.data;
          commit(SET_PROJECT_COST, projectCost);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_PROJECT](
    { commit },
    {
      name,
      solutionIDList,
      language,
      segmentID,
      industryID,
      solutionTemplateID,
      monthlyPV,
      expectedRPS,
      monthlyUU,
      itemCount,
      autoScaling,
      regionID,
      gpt_purpose,
      gpt_randomness,
      // subscriptionType
    }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`projects`, {
        name: name,
        solution_ids: solutionIDList,
        language: language,
        segment_id: segmentID,
        industry_id: industryID,
        solution_template_id: solutionTemplateID,
        monthly_pv: monthlyPV,
        expected_rps: expectedRPS,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        auto_scaling: autoScaling,
        region_id: regionID,
        // subscription_type: subscriptionType
        gpt_purpose: gpt_purpose,
        gpt_randomness: gpt_randomness,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_PROJECT](
    { commit },
    {
      project_id,
      name,
      solutionIDList,
      monthlyPV,
      expectedRPS,
      monthlyUU,
      itemCount,
      autoScaling,
      regionID,
      subscriptionType,
      gpt_purpose,
      gpt_randomness,
    }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}`, {
        name: name,
        solution_ids: solutionIDList,
        monthly_pv: monthlyPV,
        expected_rps: expectedRPS,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        auto_scaling: autoScaling,
        region_id: regionID,
        subscription_type: subscriptionType,
        gpt_purpose: gpt_purpose,
        gpt_randomness: gpt_randomness,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [START_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/start`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPGRADE_SUBSCRIPTION]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}/upgrade/subscription`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [STOP_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/stop`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TERMINATE_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/terminate`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_CREDENTIAL]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_ALL_CREDENTIAL, []);
      Api.get(`projects/${project_id}/credentials`).then(
        (response) => {
          let allCredential = response.data.credentials;

          commit(SET_ALL_CREDENTIAL, allCredential);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_CREDENTIAL]({ commit }, { project_id, name, type, whitelisted }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`projects/${project_id}/credentials`, {
        name: name,
        key_type: type,
        key_metadata: whitelisted,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_CREDENTIAL](
    { commit },
    { project_id, credential_id, name, type, whitelisted, status }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}/credentials/${credential_id}`, {
        name: name,
        key_type: type,
        key_metadata: whitelisted,
        status: status,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_RANK_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`ranks/settings`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_MAPPER]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`mappers`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          commit(SET_MAPPER, response.data.detail.response);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_MAPPER]({ commit }, { project_id, mapper_body }) {
    let key_map = mapper_body.key_map;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.post(`mappers`, {
        key_map,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
    ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_MAPPER]({ commit }, { project_id, mapper_body }) {
    let key_map = mapper_body.key_map;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.put(`mappers`, {
        key_map,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_INDICES]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`indices`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_INDICES]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.post(`indices`,
        {},
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_RANK]({ commit }, { project_id, settings }) {
    let rank_settings = settings.rank_settings;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.post(`ranks/settings`, {
        rank_settings,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
    ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_RANK_SETTINGS]({ commit }, { project_id, settings }) {
    let rank_settings = settings.rank_settings;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.put(`ranks/settings`, {
        rank_settings,
      },
        {
          headers: {
            "project-id": project_id,
          },
        }
    ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPLOAD_RECOMMENDER_DATA]({ commit }, { project_id, file }) {
    updateLoaderTo(true);

        return new Promise((resolve, reject) => {
          PersonalizerApi.post(`items/save`, file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "project-id": project_id,
                },
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [ITEMS_TRAIN]({ commit }, { project_id, train_all, train_from, train_to }) {
        updateLoaderTo(true);
        let uri = `items/train`;
        if (train_from && train_to) {
            uri = uri + `?train_from=${train_from}T00:00:00&train_to=${train_to}T00:00:00`
        }
        else {
            uri = uri + `?train_all=${train_all}`
        }
        
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(uri,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [IMAGES_TRAIN]({ commit }, { project_id, date_range }) {
        updateLoaderTo(true);
        let dateRange = date_range;
        return new Promise((resolve, reject) => {
          PersonalizerApi.post(`images/train`, {
                dateRange,
            },
            {
                headers: {
                    "project-id": project_id,
                },
            }
          ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [RANKS_TRAIN]({ commit }, { project_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
          PersonalizerApi.post(`ranks/train`,
            {},
              {
                  headers: {
                      "project-id": project_id,
                  },
              }
          ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    async [GET_TASKS_STATUS]({ commit, dispatch }, { project_id }) {

      updateLoaderTo(true);

      const [gptProfiles, defaultProfile, gptDatasets, successfullyProcessedDatasets, successfullyTrained] = await Promise.all([
        dispatch(GET_GPT_PROFILES, { project_id }),
        dispatch(GET_GPT_SETTINGS, { project_id }),
        dispatch(GET_GPT_DATASETS, { project_id }),
        dispatch(GET_ALL_TASKS, { project_id, task_type: "gpt_dataset", task_status: "success", start: 0, size: 1 }),
        dispatch(GET_ALL_TASKS, { project_id, task_type: "gpt_train", task_status: "success", start: 0, size: 1 }),
      ]);
      let tasks_status = {
        completed: false,
        gpt_profile: { completed: false, profile_id: null },
        default_profile: { completed: false },
        dataset: { task_id: null, status: null, dataset_id: null, completed: false },
        training: { task_id: null, status: null, completed: false },
      };

      if (gptProfiles.data?.detail?.response?.profiles?.length > 0) {
        tasks_status.gpt_profile.completed = true;
        tasks_status.gpt_profile.profile_id = gptProfiles.data?.detail?.response[0]?.id;
      }

     if (defaultProfile.data?.detail?.settings?.default_profile) {
        tasks_status.default_profile.completed = true;
      }
      else if(tasks_status.gpt_profile.profile_id){
        dispatch(CREATE_GPT_SETTINGS, { project_id, default_profile: tasks_status.gpt_profile.profile_id});
        tasks_status.default_profile.completed = true;
      }

      if (successfullyProcessedDatasets.data?.detail?.response?.length > 0) {
        tasks_status.dataset.completed = true;
        tasks_status.dataset.task_id = successfullyProcessedDatasets.data?.detail?.response[0]?.task_id;
        tasks_status.dataset.status = successfullyProcessedDatasets.data?.detail?.response[0]?.status;
        tasks_status.dataset.dataset_id = successfullyProcessedDatasets.data?.detail?.response[0]?.dataset_id;
      }

      if (successfullyTrained.data?.detail?.response?.length > 0) {
        tasks_status.training.completed = true;
        tasks_status.training.task_id = successfullyTrained.data?.detail?.response[0]?.task_id;
        tasks_status.training.status = successfullyTrained.data?.detail?.response[0]?.status;
      }

      tasks_status.completed = tasks_status.gpt_profile.completed && tasks_status.default_profile.completed && tasks_status.dataset.completed && tasks_status.training.completed;

      updateLoaderTo(false);
      return tasks_status;
  },
    [LOAD_TRAIN_TASKS]({ commit }, { project_id, train_type, status, start, size }) {
        if (!train_type) {
            train_type = "all_tasks";
        }

    if (!status) {
      status = "all_status";
    }
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_TRAIN_TASKS, []);
      PersonalizerApi.get(`tasks`, {
        params: {
          task_type: train_type,
          task_status: status,
          start,
          size,
        },
        headers: {
          "project-id": project_id,
        },
      }).then(
        (response) => {
          let trainTasks = response.data.detail.response;
          commit(SET_TRAIN_TASKS, trainTasks);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  async [GET_PREVIOUSLY_SETUP_STATE]({ commit, dispatch }, { project_id }) {
    updateLoaderTo(true);
    let previouslySetupState = [
      { name: "map_create", message: "", value: true, progress_value: 0, status: "failed" },
      { name: "index_create", message: "", value: true, progress_value: 0, status: "failed" },
      { name: "rank_settings", message: "", value: true, progress_value: 0, status: "failed" },
      { name: "csv_upload", message: "", value: true, progress_value: 0, status: "failed" },
    ];

    try {
      const [mapper, indices, ranks, items] = await Promise.all([
        dispatch(GET_MAPPER, { project_id }),
        dispatch(GET_INDICES, { project_id }),
        dispatch(GET_RANK_SETTINGS, { project_id }),
        dispatch(LOAD_TRAIN_TASKS, { project_id, train_type: "item_save", status: "success", start: 0, size: 1 }),
      ]);

      if (mapper.data?.detail?.response) {
        previouslySetupState[0].status = "success";
        commit(SET_MAPPER, mapper.data.detail.response);
      }
      if (indices.data?.detail?.response?.length > 0) previouslySetupState[1].status = "success";
      if (ranks.data?.detail?.response) previouslySetupState[2].status = "success";
      if (items.data?.detail?.response?.length > 0) previouslySetupState[3].status = "success";

      commit(SET_PREVIOUSLY_SETUP_STATE, {steps: previouslySetupState});
      commit(SET_PROJECT_SETUP_STATE, previouslySetupState);

    } catch (error) {
      console.error("Error fetching previously setup state:", error);
    } finally {
      updateLoaderTo(false);
    }

    return previouslySetupState;  // Return the state
  },
  [LOAD_PROJECT_COUNTS]({ commit }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_PROJECT_COUNTS, []);
      Api.get(`projects/count`).then(
        (response) => {
          let projectCounts = response.data.counts;
          commit(SET_PROJECT_COUNTS, projectCounts);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },


  [GET_CATALOG_ITEMS]({ commit }, { project_id, payload }) {
    const data = {
        "start_date": payload.start_date,
        "end_date": payload.end_date,
        "text": payload.text,
        "sort": payload.sort,
        "ranges": payload.ranges,
        "start": payload.start,
        "size": payload.size
      };
    if (payload.scroll_id) {
      data["scroll_id"] = payload.scroll_id;
    }
    updateLoaderTo(true);      
    return new Promise((resolve, reject) => {
     
  
      PersonalizerApi.post(`items/scroll`, data,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(
            (response) => {
                const catalogItems = response.data;
                commit(SET_CATALOG_ITEMS, catalogItems);
                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[REMOVE_CATALOG_ITEMS]({ commit }, { project_id, item_ids, is_delete_all, documentIds }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.delete(`items`,
      {
        data: {
            ids: item_ids,
            is_delete_all: is_delete_all,
            documentIds: documentIds
        },
        headers: {
            'Content-Type': 'application/json',
            'project-id': project_id
        }
    })
        .then((response) => {
          updateLoaderTo(false);
          resolve(response);
        })
        .catch((error) => {
          updateLoaderTo(false);
          reject(error);
        });
    });
  },
  [UPDATE_CATALOG_ITEM]({ commit }, { project_id, item_id, payload }) {
    updateLoaderTo(true);  
    return new Promise((resolve, reject) => {
      PersonalizerApi.put(`items/${item_id}`, payload,
        {
          headers: {
            "project-id": project_id,
          },
        }
      )
        .then((response) => {
          updateLoaderTo(false);
        this.selectedItem = response.data;
          resolve(response);
        })
      .catch((error) => {
        updateLoaderTo(false);
        console.error("Error updating catalog item:", error);
        reject(error);
      });
    });
  },
  [GENERATE_FILE]({ commit }, { project_id, gaip_account_id, history_type, start_date,end_date }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(`file/generate`, {
        params: {
          gaip_account_id,
          start_date,
          end_date,
          history_type,
        },
        headers: {
          "project-id": project_id,
        },
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  [SET_ALL_PROJECT](state, allProject) {
    state.allProject = allProject;
  },
  [SET_PROJECT_BY_ID](state, selectedProject) {
    state.selectedProject = selectedProject;
  },
  [SET_MAPPER](state, mapper) {
    state.mapper = mapper;
  },
  [SET_PROJECT_SETUP_STATE](state, projectSetupState) {
    state.selectedProject.setup_state = projectSetupState;
  },
  [SET_GPT_PROFILES](state, gptProfiles) {
    state.gptProfiles = gptProfiles;
  },
  [SET_GPT_DATASETS](state, gptDatasets) {
    state.gptDatasets = gptDatasets;
  },
  [SET_GPT_DATASET](state, selectedGptDataset) {
    state.selectedGptDataset = selectedGptDataset;
  },
  [SET_GPT_DATASET_SUMMARY](state, gptDatasetSummary) {
    state.gptDatasetSummary = gptDatasetSummary
  },
  [SET_GPT_PROFILE](state, selectedGptProfile) {
    state.selectedGptProfile = selectedGptProfile;
  },
  [SET_GPT_SETUP_STATE](state, value) {
    state.gptSetupState = value;
  },
  // [UPDATE_GPT_PROFILE](state, {profile}) {
  //     state.selectedGptProfile = profile;
  // },
  [SET_PROJECT_COST](state, projectCost) {
    state.projectCost = projectCost;
  },
  [SET_ALL_CREDENTIAL](state, allCredential) {
    state.allCredential = allCredential;
  },
  [SET_TRAIN_TASKS](state, trainTasks) {
    state.trainTasks = trainTasks;
  },
  [SET_PREVIOUSLY_SETUP_STATE](state, previouslySetupState) {
    state.previouslySetupState = previouslySetupState;
  },
  [SET_PROJECT_COUNTS](state, projectCounts) {
    state.projectCounts = projectCounts;
  },
  [SET_CATALOG_ITEMS](state, catalogItems) {
    state.catalogItems = catalogItems;
  },
  [CATALOG_SCROLL_ID_STACK](state, { action, value }) {
    switch (action) {
      case 'push':
        state.catalogScrollIdStack.push(value);
        break;
      case 'pop':
        state.catalogScrollIdStack.pop();
        break;
      case 'set':
        state.catalogScrollIdStack = value;
        break;
      case 'clear':
        state.catalogScrollIdStack = [];
        break;
      default:
        console.error('Unknown action:', action);
    }
  }
};

export const project = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
