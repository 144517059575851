import PersonalizerApi from "@/services/personalizer_api";
import { GET_TASK_STATUS, GET_ALL_TASKS } from "@/store/_actiontypes";
import { updateLoaderTo } from "./account";

const actions = {
    [GET_TASK_STATUS]({ commit }, { project_id, task_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(`tasks/${task_id}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [GET_ALL_TASKS]({ commit }, { project_id, task_type, task_status, start, size }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(
                `tasks?task_type=${task_type}&task_status=${task_status}&start=${start}&size=${size}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
};

export const task = {
    namespaced: true,
    actions,
};
