import Vue from "vue";
import router from "@/router";
import getEnv from "@/config/env";
import * as Sentry from "@sentry/vue";

let ignoreError = false;

Sentry.init({
  Vue,
  dsn: getEnv("VUE_APP_SENTRY_DSN"),
  release: getEnv("VUE_APP_RELEASE_VERSION"),
  // beforeBreadcrumb: (response) => {
  //     const isXhrCategory = response?.category === "xhr";
  //     const statusCode = response?.data?.status_code;
  //     ignoreError = isXhrCategory && (statusCode === 401 || statusCode === 404);
  // },
  // beforeSend: (event, hint) => {
  //   if (ignoreError) {
  //     return null;
  //   }
  //   return event;
  // },
  environment: getEnv("NODE_ENV"),
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
    // Sentry.replayIntegration(),
  ],
  trackComponents: false,
  logErrors: true,
  debug: false,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://api.gigalogy.com",
    "https://stg.api.gigalogy.com"
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: parseFloat(getEnv("VUE_APP_SENTRY_TRACES_SAMPLE_RATE")),
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example; a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: parseFloat(getEnv("VUE_APP_SENTRY_PROFILES_SAMPLE_RATE")),
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default Sentry
