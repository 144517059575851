<template>
  <v-dialog v-model="open" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4" v-if="!response">
        <v-form ref="fileGenerationForm">
          <v-row class="justify-start">
            <v-col cols="12" xs="12" md="4">
              <v-menu
                ref="fileDateRangeModal"
                v-model="fileDateRangeModal"
                :close-on-content-click="false"
                :return-value.sync="fileDateRange"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateRangeText"
                    :placeholder="
                      $vuetify.lang.t('$vuetify.daterange_placeholder_txt')
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[
                      (v) =>
                        (v.split(' ~ ').length === 2 &&
                          new Date(v.split(' ~ ')[0]) <= new Date(v.split(' ~ ')[1]))
                        || $vuetify.lang.t('$vuetify.logs_file_generation.date_range_validation')
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fileDateRange"
                  color="primary"
                  no-title
                  scrollable
                  range
                  :max="dateOfToday()"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="fileDateRangeModal = false">
                    {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                  </v-btn>
                  <v-btn text color="primary" @click="setDates">
                    {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="py-4" v-else>
        <span v-if="response.task_id">
          {{ $vuetify.lang.t("$vuetify.logs_file_generation.file_not_exists") }}
        </span>
        <div v-else>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.logs_file_generation.file_already_exists"
              )
            }}
          </p>
          <p>
            <b>
              <a
                :href="response.file_url"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.logs_file_generation.download_file_url_text"
                  )
                }}
              </a>
            </b>
          </p>
          <p>
            <b
              >{{
                $vuetify.lang.t("$vuetify.logs_file_generation.expiration")
              }}: </b
            ><span>{{ formatDate(response.expiration) }}</span>
          </p>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end" v-if="!response">
        <v-btn outlined color="error" @click="closeModal">{{
          $vuetify.lang.t("$vuetify.cancel_btn_txt")
        }}</v-btn>
        <v-btn
          color="primary"
          :loading="fileGenerationLoading"
          @click="downloadRequest"
          >{{ $vuetify.lang.t("$vuetify.generate_btn_txt") }}</v-btn
        >
      </v-card-actions>
      <v-card-actions class="justify-end" v-else>
        <v-btn outlined color="error" @click="closeResponse">{{
          $vuetify.lang.t("$vuetify.close_btn_txt")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GENERATE_FILE } from "@/store/_actiontypes";
import { dateOfToday } from "@/helpers/helper";

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    history_type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dateOfToday,
      fileDateRange: [],
      fileDateRangeModal: false,
      fileGenerationLoading: false,
      response: false,
    };
  },

  computed: {
    ...mapState({
      account: (state) => state.account.account,
      selectedProject: (state) => state.project.selectedProject,
      dateRangeText: function () {
        return this.fileDateRange.join(" ~ ");
      },
    }),
  },

  methods: {
    ...mapActions("project", [GENERATE_FILE]),

    setDates() {
      this.$refs.fileDateRangeModal.save(this.fileDateRange);
    },

    downloadRequest() {
      if (!this.$refs.fileGenerationForm.validate()) return;
      this.fileGenerationLoading = true;
      this.GENERATE_FILE({
        project_id: this.selectedProject.id,
        gaip_account_id: this.account.id,
        history_type: this.history_type,
        start_date: this.fileDateRange[0],
        end_date: this.fileDateRange[1],
      })
        .then(
          (response) => {
            this.response = response?.data.detail;
          },
          (error) => error
        )
        .finally(() => {
          this.fileGenerationLoading = false;
        });
    },

    closeResponse() {
      this.closeModal();
      this.response = false;
    },

    formatDate(date) {
      return new Date(date).toLocaleString("en-CA").split(",")[0];
    },
  },
};
</script>
